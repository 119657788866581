export const environment = {
  production: false,
  LANG: "LANG",
  HOST_BASE: "https://api.dev.vegiwise.com/",
  API_BASE: "https://api.dev.vegiwise.com/api/v1",
  JWT_EXPIRE_OFFSET: 10,
  ACCESS_TOKEN: "ACCESS_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  USERNAME: "USERNAME",
  GUEST_UID: "GUEST_UID",
  GUEST_EMAIL: "GUEST_EMAIL",
  GET_FILE_BASE_URL: "https://files-dev.vegiwise.com",
  CLIENT_ID: "cnkaudit-client",
  CLIENT_SECRET: "qkzdPQNHrTf8jNj2VBXc8XHeSeEdNXJF",
  PULLING_DWH_TIME: 30000,
  PULLING_NOTIF_TIME: 30000,
  ENOT_CALLBACK: null,
  ENOT_VERIFIER: "ENOT_VERIFIER",
};
